import Vue from "vue";

/*TITLE*/
document.title = "Edificio Zentral Ruzafa | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Edificio Zentral Ruzafa";
Vue.prototype.$subtitle = "";

/*INTRO*/
Vue.prototype.$promoter = "Edificio Zentral Ruzafa";
Vue.prototype.$introSubtitle = "";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-rivoli--20230116090107.jpg";
Vue.prototype.$image_kitchen = "cocina-rivoli-zinc--20230116090136.jpg";
Vue.prototype.$image_bath1 = "banop-op.4-dover-caliza-dover-modern-line-caliza-pvto-dover-caliza--20230116090128.jpg";
Vue.prototype.$image_bath2 = "banosec-op.4-dover-caliza-dover-modern-line-caliza-pvto-dover-caliza--20230116090115.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-rivoli--20230116090107.jpg",
  },
  {
    src: "salon-nantes-caliza--20230116090121.jpg",
  },
  {
    src: "salon-dover-caliza--20230116090127.jpg",
  },
  {
    src: "salon-bottega-caliza--20230116090137.jpg",
  },
  {
    src: "salon-ac6-natural-1l-yukon--20230116090150.jpg",
  },
  {
    src: "salon-ac6-natural-1l-mystic--20230116090159.jpg",
  },
  {
    src: "salon-ac6-natural-1l-michigan-clear--20230116090109.jpg",
  },
  {
    src: "salon-ac6-natural-1l-grande_mod--20230116090122.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-rivoli-zinc--20230116090136.jpg",
  },
  {
    src: "cocina-pav-yukon-blanca--20230116090143.jpg",
  },
  {
    src: "cocina-opc.-2-nature-grande-zinc-bottega-acero_mod--20230116090104.jpg",
  },
  {
    src: "cocina-opc.-1-michigan-clear-blanco-nubol-bottega-acero--20230116090116.jpg",
  },
  {
    src: "cocina-natural-mystic-eucalyptus-blond--20230116090124.jpg",
  },
  {
    src: "cocina-nantes-caliza-blanca--20230116090136.jpg",
  },
  {
    src: "cocina-dover-caliza-blanca--20230116090146.jpg",
  },
  {
    src: "cocina-bottega-caliza-eucalyptus-blond--20230116090156.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-op.4-dover-caliza-dover-modern-line-caliza-pvto-dover-caliza--20230116090128.jpg",
  },
  {
    src: "banop-op.3-rivoli-pvto-rivoli--20230116090136.jpg",
  },
  {
    src: "banop-op.2-revestimiento-nantes-caliza-mosaico-nantes-caliza-pvto-nantes-caliza--20230116090147.jpg",
  },
  {
    src: "banop-op.1-revestimiento-bottega-caliza-spiga-bottega-caliza-pvto-bottega-caliza--20230116090155.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banosec-op.4-dover-caliza-dover-modern-line-caliza-pvto-dover-caliza--20230116090115.jpg",
  },
  {
    src: "banosec-op.3-rivoli-pvto-rivoli--20230116090122.jpg",
  },
  {
    src: "banosec-op.2-revestimiento-nantes-caliza-mosaico-nantes-caliza-pvto-nantes-caliza--20230116090131.jpg",
  },
  {
    src: "banosec-op.1-revestimiento-bottega-caliza-spiga-bottega-caliza-pvto-bottega-caliza--20230116090137.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/ZENTRAL_RUZAFA/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/koA_hTTm2qk";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20gibraltar%2015%20valencia%20valencia%2046004",
    text: "Calle Gibraltar, 15. Valencia, Valencia, 46004",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=gran%20via%20del%20marqués%20del%20túria%2061%2046005%20valència",
    text: "Gran Via del Marqués del Túria, 61, 46005 València",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:900833818/622591490",
    text: "900833818/622591490",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@vaehomes.com",
    text: "info@vaehomes.com",
  },
];
